<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container class="px-6 mt-n16">
      <v-row>
        <v-col class="mx-auto pt-0" lg="8">
          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">Grid System</p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col v-for="n in 3" :key="n" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    One of three columns
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">Equal Width</p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col v-for="n in 2" :key="n" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    <template v-if="n === 1">1 of 2</template>
                    <template v-else>2 of 2</template>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="n in 3" :key="n" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    <template v-if="n === 1">1 of 3</template>
                    <template v-else-if="n === 2">2 of 3</template>
                    <template v-else>3 of 3</template>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Setting one column width
              </p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col
                  v-for="n in 3"
                  :key="n"
                  :cols="n === 2 ? '6' : false"
                  class="py-0"
                >
                  <v-card class="card-border my-4 pa-3">
                    <template v-if="n === 1">1 of 3</template>
                    <template v-else-if="n === 2">2 of 3 (wider)</template>
                    <template v-else>3 of 3</template>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="n in 3"
                  :key="n"
                  :cols="n === 2 ? '5' : false"
                  class="py-0"
                >
                  <v-card class="card-border my-4 pa-3">
                    <template v-if="n === 1">1 of 3</template>
                    <template v-else-if="n === 2">2 of 3 (wider)</template>
                    <template v-else>3 of 3</template>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Variable width content
              </p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="2" class="ml-auto py-0">
                  <v-card class="card-border my-4 pa-3"> 1 of 3 </v-card>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    Variable with content
                  </v-card>
                </v-col>
                <v-col cols="2" class="mr-auto py-0">
                  <v-card class="card-border my-4 pa-3"> 3 of 3 </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-card class="card-border my-4 pa-3"> 1 of 3 </v-card>
                </v-col>
                <v-col class="py-0" md="auto">
                  <v-card class="card-border my-4 pa-3">
                    Variable with content
                  </v-card>
                </v-col>
                <v-col class="py-0" lg="2">
                  <v-card class="card-border my-4 pa-3"> 3 of 3 </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Equal-width multi-row
              </p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <template v-for="n in 4">
                  <v-col :key="n" class="py-0">
                    <v-card class="card-border my-4 pa-3"> Column </v-card>
                  </v-col>
                  <v-responsive
                    v-if="n === 2"
                    :key="`width-${n}`"
                    width="100%"
                  ></v-responsive>
                </template>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Mix and match
              </p>
            </div>
            <v-card-text class="card-padding">
              <!-- Stack the columns on mobile by making one full-width and the other half-width -->
              <v-row>
                <v-col cols="12" md="8" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    .col-12 .col-md-8
                  </v-card>
                </v-col>
                <v-col cols="6" md="4" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    .col-6 .col-md-4
                  </v-card>
                </v-col>
              </v-row>

              <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
              <v-row>
                <v-col v-for="n in 3" :key="n" cols="6" md="4" class="py-0">
                  <v-card class="card-border my-4 pa-3">
                    .col-6 .col-md-4
                  </v-card>
                </v-col>
              </v-row>

              <!-- Columns are always 50% wide, on mobile and desktop -->
              <v-row>
                <v-col v-for="n in 2" :key="n" cols="6" class="py-0">
                  <v-card class="card-border my-4 pa-3"> .col-6 </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "grid",
  components: {
    HeaderTopDashboard,
  },
};
</script>
